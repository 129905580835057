"use client";

import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import RouteListItem from "./RouteListItem";

import { cn } from "@/lib/utils";
import {
  AvailableLanguageTag,
  languageTag,
} from "../../../../paraglide/runtime";
import Image from "next/image";
import { usePathname, useRouter } from "@/lib/i18n";
import Cookies from "js-cookie"; // Import this
import brFlag from "/public/brazil.png";
import esFlag from "/public/spain.png";

export const LanguageSelector = ({ expanded, disabled, itemVariants }) => {
  const pathname = usePathname();
  const router = useRouter();
  const [moreOpen, setMoreOpen] = React.useState(false);
  const [cadastrosAnchorEl, setCadastrosAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const cadastrosOpen = Boolean(cadastrosAnchorEl);
  const handleMouseLeave = () => {
    handleCadastrosClose();
  };

  const handleCadastrosClose = () => {
    setCadastrosAnchorEl(null);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setMoreOpen(false);
    setCadastrosAnchorEl(e.currentTarget);
  };

  const handleLanguageChange = (locale: AvailableLanguageTag) => {
    Cookies.set("NEXT_LOCALE", locale, { expires: 365, path: "/" });
    router.push(pathname, { locale });
    handleCadastrosClose();
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
        className={cn(
          cadastrosOpen ? "bg-white/10" : "rounded-md",
          "rounded-lg w-full"
        )}
      >
        <RouteListItem
          route="#"
          title="Idioma"
          icon={
            <div className="h-6 w-6 relative">
              {languageTag() === "pt-br" ? (
                <Image
                  src={brFlag}
                  alt="br-flag"
                  fill
                  sizes="24px"
                  style={{ objectFit: "contain" }}
                />
              ) : languageTag() === "es" ? (
                <Image
                  src={esFlag}
                  alt="es-flag"
                  fill
                  sizes="24px"
                  style={{ objectFit: "contain" }}
                />
              ) : null}
            </div>
          }
          expanded={expanded}
          itemVariants={itemVariants}
          disabled={disabled}
        />
      </div>
      <Menu
        id="demo-positioned-menu"
        disablePortal
        aria-labelledby="demo-positioned-button"
        anchorEl={cadastrosAnchorEl}
        open={cadastrosOpen}
        onClose={handleCadastrosClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        className="ml-2 rounded"
        sx={{ pt: 1, pb: 1, display: "flex", alignItems: "center" }}
        MenuListProps={{ onMouseLeave: handleMouseLeave }}
      >
        <Typography variant="h6" component="div" className="ml-6 mr-6">
          Idiomas
        </Typography>
        <MenuItem
          onClick={() => handleLanguageChange("pt-br" as AvailableLanguageTag)}
        >
          <Image
            src={brFlag}
            alt="br-flag"
            width={24}
            height={24}
            style={{ width: "auto", height: "auto" }}
          />
          <p className="ml-2">Português (BR)</p>
        </MenuItem>
        <MenuItem
          onClick={() => handleLanguageChange("es" as AvailableLanguageTag)}
        >
          <Image
            src={esFlag}
            alt="es-flag"
            width={24}
            height={24}
            style={{ width: "auto", height: "auto" }}
          />
          <p className="ml-2">Español</p>
        </MenuItem>
      </Menu>
    </>
  );
};
