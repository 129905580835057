"use client";
import * as m from "@/../paraglide/messages";

import { cn } from "@/lib/utils";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useContext } from "react";
import { colorSchemeContext } from "../../providers/themeModeProvider";
import { Tooltip } from "@mui/material";
import { motion } from "framer-motion";

export const ThemeSwitcher = ({ expanded, itemVariants }) => {
  const { colorMode, setMode } = useContext(colorSchemeContext);
  return (
    <Tooltip followCursor title="Modo Noturno" placement="right">
      <div
        onClick={() => setMode(colorMode === "dark" ? "light" : "dark")}
        className={cn(
          "w-full inline-flex justify-start items-center text-white hover:bg-white/10 rounded-lg cursor-pointer p-[10px]"
        )}
      >
        {colorMode === "dark" ? <Brightness4 /> : <Brightness7 />}

        <motion.h1
          layout
          initial="hidden"
          animate={expanded ? "visible" : "hidden"}
          variants={itemVariants}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: expanded ? "auto" : 0,
            opacity: expanded ? 1 : 0,
            marginLeft: expanded ? "8px" : 0,
          }}
          className="text-white text-xs font-normal whitespace-nowrap"
        >
          {"Modo: " + (colorMode === "dark" ? "Noite" : "Dia")}
        </motion.h1>
      </div>
    </Tooltip>
  );
};
