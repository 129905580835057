"use client";
import * as m from "@/../paraglide/messages";

import React, { useTransition, useEffect, useState } from "react";
import { Link as NextLink } from "@/lib/i18n";
import { usePathname, useRouter } from "@/lib/i18n";
import { CircularProgress } from "@mui/material";
import { createPortal } from "react-dom";
import useCustomLocalStorage from "./useCustomLocalStorage";

function SpinnerPortal({ children }: React.PropsWithChildren) {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return mounted ? createPortal(<>{children}</>, document.body) : null;
}

/**
 * A custom Link component that wraps Next.js's next/link component.
 */
export function Link({
  href,
  children,
  replace,
  target,
  disabled,
  ...rest
}: Parameters<typeof NextLink>[0] & { disabled?: boolean }) {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const [showSpinner, setShowSpinner] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [loggingOut, setLoggingOut] = useCustomLocalStorage(
    "loggingOut",
    false
  );
  // const { loading, hydrated } = useDashboardFetch();

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (isPending) {
      timeoutId = setTimeout(() => {
        setShowSpinner(true);
      }, 300); // Show spinner after 300ms
    } else {
      setShowSpinner(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isPending]);

  const handleNavigation = (e) => {
    if (target === "_blank") {
      return;
    }

    startTransition(() => {
      const url = href.toString();
      if (replace) {
        router.replace(url);
      } else {
        router.push(url);
      }
    });
  };

  if (disabled) {
    //disable clicks
    return <>{children}</>;
  }
  return (
    <>
      {showSpinner && (
        <SpinnerPortal>
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y + 25,
              left: cursorPosition.x + 25,
              transform: "translate(-50%, -50%)", // Center the spinner on the cursor
              zIndex: 99999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pointerEvents: "none", // Let mouse events pass through
            }}
          >
            <CircularProgress size={30} />
          </div>
        </SpinnerPortal>
      )}
      <NextLink
        href={href}
        onClick={handleNavigation}
        target={target}
        {...rest}
      >
        {children}
      </NextLink>
    </>
  );
}
