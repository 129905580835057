import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { usePathname, useRouter } from "@/lib/i18n";
import { Tooltip } from "@mui/material";
import { Link } from "@/hooks/CustomLink";
import { motion } from "framer-motion";

const RouteListItem = ({
  route,
  title,
  icon,
  expanded,
  disabled,
  itemVariants,
}) => {
  const [active, setActive] = useState(false);
  const router = useRouter();
  const currentRoute = usePathname();

  useEffect(() => {
    if (currentRoute.includes(route)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [route, currentRoute]);
  return (
    <Link href={route} className="w-full" disabled={disabled}>
      <Tooltip
        followCursor
        title={disabled || expanded ? "" : title}
        placement="right"
      >
        <div
          id={`navbar-routeListItem-${title}`}
          className={cn(
            active ? "border border-white" : "",
            disabled
              ? "cursor-not-allowed opacity-20"
              : "cursor-pointer hover:bg-white/10",
            "p-[10px] inline-flex justify-start items-center text-white rounded-lg w-full"
          )}
        >
          {icon}
          <motion.h1
            layout
            initial="hidden"
            animate={expanded ? "visible" : "hidden"}
            variants={itemVariants}
            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: expanded ? "auto" : 0,
              opacity: expanded ? 1 : 0,
              marginLeft: expanded ? "8px" : 0,
            }}
            className="text-white text-xs font-normal whitespace-nowrap"
          >
            {title}
          </motion.h1>
        </div>
      </Tooltip>
    </Link>
  );
};

export default RouteListItem;
